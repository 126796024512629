import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { CardCtaContained } from 'common/components/molecules/CardCta'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'

import React from 'react'

export interface ContactCardProps {
    icon?: string
    title: string
    subs: string
    phoneNumber: string
    subtitle: string
    times: string
    email: string
    ctaLabel?: string
    ctaUrl?: string
}

export const ContactCard: React.FC<ContactCardProps> = ({
    icon,
    title,
    subs,
    phoneNumber,
    subtitle,
    times,
    email,
    ctaLabel,
    ctaUrl
}) => {
    const useStyles = makeStyles({
        sectionHeading: {
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            paddingBottom: '25px',
            fontSize: '22px',
            color: 'black'
        },
        mainTitle: {
            fontFamily: 'Assistant',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '32px',
            lineHeight: '104%'
        },
        phoneTitle: {
            fontFamily: 'Assistant',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '132%',
            alignitems: 'center',
            color: '#3A3160',
            marginBottom: '24px'
        },
        title: {
            marginBottom: '50px',
            fontSize: '32px',
            color: '#4E4670',
            lineHeight: '33px'
        },
        subs: {
            fontFamily: 'Assistant',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '12px',
            lineHeight: '100%',
            letterSpacing: '0.04em',
            textTransform: 'uppercase',
            marginTop: '-40px'
        },
        subtitle: {
            fontSize: 16,
            fontWeight: 700,
            fontStyle: 'normal',
            color: '#766f90',
            marginBottom: '24px'
        },
        icon: {
            width: '100%',
            maxWidth: '56px',
            marginRight: '5%'
        },
        cta: {
            background: '#CFD4FA',
            color: '#717FE2'
        },
        h6: {
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 1.32,
            color: '#3A3160',
            marginBottom: '24px'
        }
    })
    const classes = useStyles()

    return (
        <>
            <Grid
                data-testid='ContactCard'
                container
                style={{
                    borderRadius: '16px'
                }}
                spacing={2}
            >
                <Grid item xs={1} md={2}>
                    <img
                        alt='Contact Icon'
                        className={classes.icon}
                        src={icon}
                    />
                </Grid>
                <Grid item xs={11} md={10}>
                    {title && (
                        <Text className={classes.title} html label={title} />
                    )}
                    {subs && (
                        <Text className={classes.subs} html label={subs} />
                    )}
                    {phoneNumber && (
                        <>
                            <Spacer variant={'xs'} />
                            <a
                                href={'tel:' + phoneNumber}
                                className={classes.h6}
                            >
                                {phoneNumber}
                            </a>
                        </>
                    )}
                    {subtitle && (
                        <>
                            <Spacer variant={'xs'} />
                            <Text
                                html
                                label={subtitle}
                                variant='body1'
                                className={classes.subtitle}
                            />
                        </>
                    )}
                    {times && <Text html label={times} variant='body1' />}
                    {email && (
                        <>
                            <a href={'mailto:' + email} className={classes.h6}>
                                {email}
                            </a>
                            <Spacer variant={'xs'} />
                        </>
                    )}
                    {ctaLabel && (
                        <CardCtaContained label={ctaLabel} url={ctaUrl} />
                    )}
                </Grid>
            </Grid>
        </>
    )
}
