import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { CardCtaContained } from 'common/components/molecules/CardCta'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import React from 'react'

export interface DoubleCardProps {
    icon?: string
    title: string
    subs: string
    phoneNumberLeft: string
    subtitleLeft: string
    timesLeft: string
    emailLeft: string
    phoneNumberRight: string
    subtitleRight: string
    timesRight: string
    emailRight: string
    ctaLabel?: string
    ctaUrl?: string
}

export const DoubleCard: React.FC<DoubleCardProps> = ({
    icon,
    title,
    subs,
    phoneNumberLeft,
    subtitleLeft,
    timesLeft,
    emailLeft,
    phoneNumberRight,
    subtitleRight,
    timesRight,
    emailRight,
    ctaLabel,
    ctaUrl
}) => {
    const useStyles = makeStyles({
        mainTitle: {
            fontFamily: 'Assistant',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '32px',
            lineHeight: '104%'
        },
        sectionHeading: {
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            paddingBottom: '25px',
            fontSize: '22px',
            color: 'black'
        },
        phoneTitle: {
            fontFamily: 'Assistant',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '132%',
            alignitems: 'center',
            color: '#3A3160',
            marginBottom: '24px'
        },
        title: {
            marginBottom: '50px',
            fontSize: '32px',
            color: '#4E4670',
            lineHeight: '33px'
        },
        subs: {
            fontFamily: 'Assistant',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '12px',
            lineHeight: '100%',
            letterSpacing: '0.04em',
            textTransform: 'uppercase',
            marginTop: '-40px'
        },
        subtitle: {
            fontSize: 20,
            fontWeight: 600,
            fontStyle: 'normal',
            color: '#0C1E68',
            marginBottom: '24px'
        },
        icon: {
            width: '100%',
            maxWidth: '56px',
            marginRight: '5%'
        },
        cta: {
            background: '#CFD4FA',
            color: '#717FE2'
        },
        h6: {
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 1.32,
            color: '#3A3160',
            marginBottom: '24px'
        }
    })
    const classes = useStyles()

    return (
        <>
            <Grid
                data-testid='DoubleCard'
                container
                style={{
                    borderRadius: '16px'
                }}
                spacing={2}
            >
                <Grid item xs={1} md={1}>
                    <img
                        alt='Contact Icon'
                        className={classes.icon}
                        src={icon}
                    />
                </Grid>
                <Grid item xs={11} md={5}>
                    <Text className={classes.title} html label={title} />
                    <Text className={classes.subs} html label={subs} />
                    <Spacer variant={'xs'} />
                    <Text
                        html
                        label={subtitleLeft}
                        variant='body1'
                        className={classes.subtitle}
                    />

                    <a href={'tel:' + phoneNumberLeft} className={classes.h6}>
                        {phoneNumberLeft}
                    </a>
                    <Spacer variant={'xs'} />
                    <Text html label={timesLeft} variant='body1' />
                    <a href={'mailto:' + emailLeft} className={classes.h6}>
                        {emailLeft}
                    </a>
                    <Spacer variant={'xs'} />
                    {ctaLabel && (
                        <CardCtaContained
                            label={ctaLabel}
                            url={ctaUrl}
                            variant='secondary'
                        />
                    )}
                </Grid>
                <Grid item xs={1} md={1} />
                <Grid item xs={11} md={5}>
                    <Text
                        className={classes.title}
                        html
                        label='None'
                        style={{ opacity: '0' }}
                    />
                    <Text
                        html
                        label={subtitleRight}
                        variant='body1'
                        className={classes.subtitle}
                    />
                    <a href={'tel:' + phoneNumberRight} className={classes.h6}>
                        {phoneNumberRight}
                    </a>
                    <Spacer variant={'xs'} />
                    <Text html label={timesRight} variant='body1' />
                    <a href={'mailto:' + emailRight} className={classes.h6}>
                        {emailRight}
                    </a>
                </Grid>
            </Grid>
        </>
    )
}
