import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { ContactUsExisting } from 'common/components/templates/ContactUsExisting'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface ContactUsExistingPageProps {
    pageContext: any
}

export const ContactUsExistingPage: React.FC<ContactUsExistingPageProps> = ({
    pageContext
}) => {
    return <ContactUsExisting {...getExistingPlanPageData(pageContext)} />
}

export default ContactUsExistingPage

export const getExistingPlanPageData = (data: any) => ({
    layout: {
        ...getLayoutData(data),
        footer: {
            ...getLayoutData(data).footer,
            regLine: data?.footer__regulatory_text?.[0].text
        }
    },
    jumbotron: getJumbotronData(data),
    section_heading: data.section_heading,
    life_insurance: {
        icon: data.life_insurance__icon,
        title: data.life_insurance__title,
        subs: data.life_insurance__subs,
        phoneNumber: data.life_insurance__phone_number,
        subtitle: data.life_insurance__subtitle,
        times: data.life_insurance__times,
        email: data.life_insurance__email,
        ctaLabel: data.cta_label,
        ctaUrl: data.cta_url
    },
    health_insurance: {
        icon: data.health_insurance__icon,
        title: data.health_insurance__title,
        subs: data.health_insurance__subs,
        phoneNumber: data.health_insurance__phone_number,
        subtitle: data.health_insurance__subtitle,
        times: data.health_insurance__times,
        email: data.health_insurance__email,
        ctaLabel: data.cta_label,
        ctaUrl: data.cta_url
    },
    investments: {
        icon: data.investments__icon,
        title: data.investments__title,
        subs: data.life_insurance__subs,
        phoneNumber: data.investments__phone_number,
        subtitle: data.investments__subtitle,
        text: data.investments__text,
        times: data.investments__times,
        email: data.investments__email,
        ctaLabel: data.cta_label,
        ctaUrl: data.cta_url
    },
    pensions: {
        icon: data.double_card__icon,
        title: data.double_card__title,
        subs: data.life_insurance__subs,
        subtitleLeft: data.double_card__subtitle_left,
        subtitleRight: data.double_card__subtitle_right,
        phoneNumberLeft: data.double_card__phone_number_left,
        phoneNumberRight: data.double_card__phone_number_right,
        timesLeft: data.double_card__times_left,
        timesRight: data.double_card__times_right,
        emailLeft: data.double_card__email_left,
        emailRight: data.double_card__email_right,
        ctaLabel: data.cta_label,
        ctaUrl: data.cta_url
    }
})
