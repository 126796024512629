import { Box, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from '../organisms/Layout'
import { Wrapper } from '../molecules/Wrapper'
import { Text } from 'common/components/molecules/Text'
import { Spacer } from '../molecules/Spacer'
import { DoubleCard, DoubleCardProps } from '../organisms/DoubleCard'
import {
    ContactCard,
    ContactCardProps
} from 'common/components/molecules/ContactCard'
import { useDesktop } from 'common/hooks/useDesktop'

export interface ContactUsExistingProps {
    jumbotron: JumbotronProps
    layout: LayoutProps
    life_insurance: ContactCardProps
    health_insurance: ContactCardProps
    pensions: DoubleCardProps
    investments: ContactCardProps
    section_heading: string
}

export const ContactUsExisting: React.FC<ContactUsExistingProps> = ({
    jumbotron,
    layout,
    life_insurance,
    health_insurance,
    investments,
    pensions,
    section_heading
}) => {
    const useStyles = makeStyles({
        sectionHeading: {
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            color: '#3A3160'
        },
        mainTitle: {
            fontFamily: 'Assistant',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '32px',
            lineHeight: '104%'
        },
        phoneTitle: {
            fontFamily: 'Assistant',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '132%',
            alignitems: 'center',
            color: '#3A3160'
        },
        title: {
            marginBottom: '50px',
            fontSize: '32px',
            color: '#4E4670',
            lineHeight: '33px'
        },
        icon: {
            width: '100%',
            maxWidth: '56px',
            marginRight: '5%'
        }
    })
    const classes = useStyles()
    const desktop = useDesktop()

    return (
        <>
            <Layout {...layout}>
                <Jumbotron {...jumbotron} />

                <Wrapper component='section'>
                    <Spacer variant='md' />
                    <Text
                        label={section_heading}
                        className={classes.sectionHeading}
                        variant='h4'
                    />
                    <Spacer variant='md' />
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            md={5}
                            style={{
                                background: '#fff',
                                borderRadius: '16px',
                                padding: '20px'
                            }}
                        >
                            <Box width={'100%'} style={{ margin: '0px 12px' }}>
                                <Box display='flex'>
                                    <ContactCard
                                        icon={life_insurance.icon}
                                        title={life_insurance.title}
                                        subs={life_insurance.subs}
                                        phoneNumber={life_insurance.phoneNumber}
                                        subtitle={life_insurance.subtitle}
                                        times={life_insurance.times}
                                        email={life_insurance.email}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={1} md={1} />
                        <Spacer variant={desktop ? 'xs' : 'md'} />
                        <Grid
                            item
                            xs={12}
                            md={5}
                            style={{
                                background: '#fff',
                                borderRadius: '16px',
                                padding: '20px'
                            }}
                        >
                            <Box width={'100%'} style={{ margin: '0px 12px' }}>
                                <Box display='flex'>
                                    <ContactCard
                                        icon={health_insurance.icon}
                                        title={health_insurance.title}
                                        subs={health_insurance.subs}
                                        phoneNumber={
                                            health_insurance.phoneNumber
                                        }
                                        subtitle={health_insurance.subtitle}
                                        times={health_insurance.times}
                                        email={health_insurance.email}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Spacer variant={desktop ? 'xs' : 'md'} />
                    <Grid container spacing={2}>
                        <Grid
                            item
                            lg={11}
                            xs={12}
                            md={3}
                            style={{
                                background: '#fff',
                                borderRadius: '16px',
                                padding: '20px'
                            }}
                        >
                            <Box width={'100%'} style={{ margin: '0px 12px' }}>
                                <Box display='flex'>
                                    <DoubleCard
                                        icon={pensions.icon}
                                        title={pensions.title}
                                        subs={pensions.subs}
                                        phoneNumberLeft={
                                            pensions.phoneNumberLeft
                                        }
                                        phoneNumberRight={
                                            pensions.phoneNumberRight
                                        }
                                        subtitleLeft={pensions.subtitleLeft}
                                        subtitleRight={pensions.subtitleRight}
                                        emailLeft={pensions.emailLeft}
                                        emailRight={pensions.emailRight}
                                        timesLeft={pensions.timesLeft}
                                        timesRight={pensions.timesRight}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Spacer variant={desktop ? 'xs' : 'md'} />

                    <Grid
                        item
                        xs={12}
                        md={5}
                        style={{
                            background: '#fff',
                            borderRadius: '16px',
                            padding: '20px'
                        }}
                    >
                        <Box
                            mt={6}
                            width={'100%'}
                            style={{ margin: '0px 12px' }}
                        >
                            <Box display='flex'>
                                <ContactCard
                                    icon={investments.icon}
                                    title={investments.title}
                                    subs={investments.subs}
                                    phoneNumber={investments.phoneNumber}
                                    subtitle={investments.subtitle}
                                    times={investments.times}
                                    email={investments.email}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Wrapper>
            </Layout>
        </>
    )
}
